import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Menu.css';

const Menu = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Menu.js useEffect.');
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  return (
    <div className="menu">
      <h2>Menu</h2>
      <ul>
        <li><Link to="/dashboard">Dashboard</Link></li>
        <li><Link to="/config">Config</Link></li>
        <li style={{ marginTop: 'auto' }}><button onClick={handleLogout}>Logout</button></li>
      </ul>
    </div>
  );
};

export default Menu;
