import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGetData } from '../../utils/api';
import './Dashboard.css';

const Dashboard = ({clientData, setClientData}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      try{
        setLoading(true);
        const response = await fetchGetData('admin/clients');
        setClientData(response);
      } catch (error) {
        if (error.message === 'Unauthorized') {
          navigate('/login');
        } else {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, [navigate, setClientData]);

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      {
        !loading && !error && clientData.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Client ID</th>
                <th>Client Name</th>
              </tr>
            </thead>
            <tbody>
              {clientData.map(client => (
                <tr key={client.client_id}>
                  <td>{client.client_id}</td>
                  <td>{client.client_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      }
    </div>
  );
};

export default Dashboard;
