import React from 'react';
import './Config.css';

const Config = ({clientData}) => {


  return (
    <div className="configs">
      <h1>Configs</h1>
      <table>
        <thead>
          <tr>
            <th>Client</th>
            <th>Model</th>
            <th>Parameter</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {clientData.map(client => (
            <tr key={client.client_id}>
              <td>{client.client_id}</td>
              <td>{client.client_name}</td>
              <td>Parameter 1</td>
              <td>
                <input type="text" defaultValue="Value" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Config;
