import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/login/Login';
import DashboardPage from './components/dashboard/DashboardPage';
import FrontPage from './components/frontpage/FrontPage';
import DashBoard from './components/dashboard/Dashboard/Dashboard';
import Config from './components/dashboard/Config/Config';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    console.log('App.js useEffect.')
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage setAuthenticated={setAuthenticated}/>} />
        <Route path="/login" element={<Login setAuthenticated={setAuthenticated} setToken={setToken} refreshToken={refreshToken} setRefreshToken={setRefreshToken} />} />
        <Route path="/dashboard" element={authenticated ? <DashboardPage RightComponent={DashBoard} token={token}/> : <Navigate to="/" />} />
        <Route path="/config" element={authenticated ? <DashboardPage RightComponent={Config} token={token} /> : <Navigate to="/" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
