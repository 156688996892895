const API_URL = process.env.REACT_APP_API_URL;

const getDefaultHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  };
};

const fetchData = async (path, method = 'GET', payload = null, customHeaders = {}) => {
  const url = `${API_URL}/${path}`;
  const headers = {
    ...getDefaultHeaders(),
    ...customHeaders
  };

  const options = {
    method,
    headers
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  console.log(url, options);
  let response = await fetch(url, options);

  if (response.status === 401) {
    console.log('Token expired, attempting to refresh...');
    const refreshed = await refreshAccessToken();
    if (refreshed) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      options.headers = headers;
      response = await fetch(url, options);
    } else {
      throw new Error('Unauthorized');
    }
  }

  if (!response.ok) {
    const error = await response.json();
    console.error('Error fetching data:', error);
    throw new Error(error.message || 'Failed to fetch data');
  }

  return response.json();
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    console.error('No refresh token found');
    return false;
  }

  try {
    const response = await fetch(`${API_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      },
      body: JSON.stringify({ refreshToken })
    });

    if (!response.ok) {
      console.error('Failed to refresh token:', response.status, response.statusText);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return false;
    }

    const data = await response.json();
    localStorage.setItem('token', data.access_token);
    console.log('Token refreshed successfully');
    return true;
  } catch (error) {
    console.error('Error refreshing token:', error);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return false;
  }
};

export const fetchGetData = (path, customHeaders = {}) => {
  return fetchData(path, 'GET', null, customHeaders);
};

export const fetchPostData = (path, payload, customHeaders = {}) => {
  return fetchData(path, 'POST', payload, customHeaders);
};

export const uploadFile = async (file) => {
  const url = `${API_URL}/files/upload`;
  const formData = new FormData();
  formData.append('file', file);

  const token = localStorage.getItem('token');
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

  let response = await fetch(url, {
    method: 'POST',
    headers,
    body: formData
  });

  if (response.status === 401) {
    console.log('Token expired, attempting to refresh...');
    const refreshed = await refreshAccessToken();
    if (refreshed) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      response = await fetch(url, {
        method: 'POST',
        headers,
        body: formData
      });
    } else {
      throw new Error('Unauthorized');
    }
  }

  if (!response.ok) {
    const error = await response.json();
    console.error('Error uploading file:', error);
    throw new Error(error.message || 'Failed to upload file');
  }

  return response.json();
};
