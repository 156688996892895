import React, { useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGetData } from '../utils/api';
import './FrontPage.css';

const FrontPage = ({setAuthenticated}) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('FrontPage.js useEffect.');
    const validateToken = async () => {
      const storedToken = localStorage.getItem('token');
      const storedRefreshToken = localStorage.getItem('refreshToken');
      if (storedToken && storedRefreshToken) {
        const tokenValid = await fetchGetData('auth/validate');
        if (tokenValid) {
          console.log('Token is valid.');
          setAuthenticated(true);
          navigate('/dashboard');
        } else {
          console.log('Token is invalid.');
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          navigate('/');
        }
      }
    }

    validateToken();
  }, [navigate, setAuthenticated]);

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div className="front-page-container">
      <h1 className="title">Varlet.ai</h1>
      <img src={`${process.env.PUBLIC_URL}/helmet.webp`} className="app-logo" alt="logo" />
      <h2 className="subtitle">Internal Admin Page</h2>
      <button className="login-button" onClick={handleLogin}>Login</button>
    </div>
  );
};

export default FrontPage;
