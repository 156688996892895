// src/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPostData } from '../utils/api';

const Login = ({ setAuthenticated, setToken, setRefreshToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const path = "admin/login"

  const handleLogin = async (e) => {
    e.preventDefault();

    try{
      const response = await fetchPostData(path, { username, password });
      const token = response.access_token;
      const refreshToken = response.refresh_token;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);

      setAuthenticated(true);
      setToken(token);
      setRefreshToken(refreshToken);
      
      navigate('/dashboard');
    } catch (error) {
      alert('Invalid credentials');
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
