import React, { useState } from 'react';
import Menu from './Menu/Menu';
import './DashboardPage.css';

const DashboardPage = ({ RightComponent, token }) => {
  const [clientData, setClientData] = useState([]);

  return (
    <div className="dashboard-page">
      <Menu />
      <div className="right-component">
        <RightComponent token={token} clientData={clientData} setClientData={setClientData}/>
      </div>
    </div>
  );
};

export default DashboardPage;
